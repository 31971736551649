@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  width: auto;
  height: auto;
  border: 0;
  padding: 0;

  &:hover {
    & .pinIcon {
      filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.7));
    }
  }
}

.pinIcon {
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.2));

  /* Effect */
  transition: var(--transitionStyleButton);
}

.pinIconActive {
  filter: drop-shadow(0 3px 6px rgba(0, 0, 0, 0.7));
}
