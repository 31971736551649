@import '../../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  flex: 1;

  /* Position */
  position: relative;

  /* Style */
  background-color: var(--colorWhite);
}

.filters {
  display: flex;
  align-items: center;

  & > * {
    /* Border right on all elements */
    border-right: 1px solid var(--marketplaceColor);

    /* Height on all items expect last */
    &:not(:last-child) {
      height: auto;
    }

    /* Border left just on first-child */
    &:first-child {
      border-left: 1px solid var(--marketplaceColor);
    }
    &:last-child {
      flex: 1;
    }
  }
}
